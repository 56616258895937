import React from 'react'
import Footer from '../components/Footer'
import Header, { MenuId } from '../components/Header'
import { CookiePopUp } from './CookiePopUp'
import styles from './Layout.module.scss'
import ErrorBoundary from './ErrorBoundary'
import CookieNotice from './CookieNotice'


/**
 * Layout component.
 */

interface LayoutProps
{
    children :React.ReactNode,
    menuId? : MenuId,
    hidePopUp?: boolean,
    extraWide?: boolean,
    hideCookieNotice?: boolean
}
export default function Layout(props: LayoutProps) {

    let contentStyle = styles.mainContent + (props.extraWide?(" " + styles.extraWide):"");

    return (
        <>
        {!props.hidePopUp && <CookiePopUp/>}
        <div className={styles.wholePage}>
            <Header menuId={props.menuId} extraWide={props.extraWide}/>
            <div className={styles.mainContainer}>
            <div className={contentStyle}>
                <ErrorBoundary>
            <main>{props.children}</main>
            </ErrorBoundary>
            </div>
            </div>
        
            <Footer extraWide = {props.extraWide}/>
        </div>
        <CookieNotice hideCookieNotice={props.hideCookieNotice}/>
        </>
    )
}